<template>
  <div class="containerDiv flex">
    <div class="tinymc">
      <tinymc
        v-model="contentUpdate"
        :height="200"
        :type="typeCancle"
        @cancle="cancle"
      />
      <div v-if="type === '4'" class="text">
        <p class="title">本次更新内容：</p>
        <el-input
          v-model="textContent"
          type="textarea"
          placeholder="请输入内容"
          show-word-limit
          :rows="5"
        />
      </div>
    </div>
    <div class="btn">
      <el-button @click="cancleHandle"> 取消 </el-button>
      <el-button type="primary" @click="okHandle"> 保存 </el-button>
      <!-- <el-button @click="cancleHandle">取消</el-button> -->
    </div>
  </div>
</template>

<script>
import tinymc from '@/components/tiny/index';
// import { getProtocolText, addProtocolText } from '@/api/privacy/index';

export default {
  components: {
    tinymc,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contentUpdate: '',
      textContent: '',
      typeCancle: '',
    };
  },
  created() {
    this.type ? this.getProtocolText() : (this.contentUpdate = '');
  },
  methods: {
    // 获取详情
    getProtocolText() {
      // const params = {
      //   type: this.type,
      // };
      // getProtocolText(params)
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.contentUpdate = res.data;
      //     } else {
      //       this.contentUpdate = '';
      //     }
      //   })
      //   .catch();
    },
    // 取消
    cancleHandle() {
      this.$confirm('请确认要清空所有修改吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.typeCancle = 'cancle';
          this.$nextTick(() => {
            this.getProtocolText();
          });
        })
        .catch(() => {
          this.$message.info('取消');
        });
    },
    // 选中
    okHandle() {
      const tips =
        '请再三确认<span style="color:red">隐私政策全部内容</span>和<span style="color:red">本次更新内容</span>准确无误，提交后将直接更新到 XX健康App,所有用户打开App后将收到更新通知！！！';
      if (this.type === '4') {
        this.$confirm(`${tips}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            this.submit();
          })
          .catch(() => {
            this.$message.info('取消');
          });
      } else {
        this.submit();
      }
    },
    submit() {
      // const params = {
      //   type: this.type,
      //   content: this.contentUpdate,
      //   serviceNoticeContent: this.textContent,
      // };
      // addProtocolText(params)
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.$message.success('保存成功');
      //     }
      //   })
      //   .catch();
    },
    // 组建的回调
    cancle() {
      this.typeCancle = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.tinymc {
  align-self: center;
  margin-top: 50px;
  width: 80%;
}
.text {
  margin-top: 50px;
}
.btn {
  margin: 40px 0px;
}
.title {
  text-align: left;
}
.el-message-box__status {
  top: 20%;
}
</style>
